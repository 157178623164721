<template>
  <div class="mx-auto mt-8 w-2/3">
    <div
      v-for="gridRow in gridRows"
      :key="gridRow"
      class="flex flex-row space-x-2.5 mb-2 justify-between"
    >
      <div class="img-container" v-for="picture in gridRow" :key="picture.id" :style="imgContainerStyles">
        <a :href="'#' + imgViewBaseId + picture.id">
          <img
            :src="picture.imgSmall"
            :alt="picture.title"
            :style="imgStyles"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageGrid",
  data: function () {
    this.pictures = this.pictureData.map((picture) => {
      return {
        ...picture,
        imgSmall: require(`@/assets/img/${picture.imgSmall}`),
      };
    });
    return {};
  },
  props: {
    gridCols: Number,
    pictureData: Array,
    imgViewBaseId: String,
    imgAspectRatio: Number,
  },
  computed: {
    gridRows: function () {
      const gridRows = [];
      for (let i = 0; i < this.pictures.length; i += this.gridCols) {
        let row = this.pictures.slice(i, i + this.gridCols);
        gridRows.push(row);
      }
      return gridRows;
    },
    rowImgWidth: function () {
      return this.$refs.gridContainer.clientWidth / (this.gridCols - 1);
    },
    imgContainerStyles: function () {
      return {
        width: `${90 / this.gridCols}%`,
      };
    },
    imgStyles: function () {
      return {
        width: '100%',
        aspectRatio: this.imgAspectRatio,
      };
    },
  },
};
</script>

<style scoped>
:root {
  --img-width: var(--img-width);
}

.img-container {
  /*max-width: var(--img-width);*/
}
</style>
