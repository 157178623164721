<template>
  <section>
    <div class="w-full h-screen mt-20 flex">
      <div class="w-2/3 h-3/4 m-auto flex flex-col justify-center">
        <div class="mx-auto max-w-full max-h-full my-auto" style="width: max-content">
          <img
            class="max-w-full max-h-full w-auto h-auto"
            :src="pictureData.imgFull"
            :alt="pictureData.title"
          />
          <p class="description-text title-text mt-7">{{ pictureData.title }}</p>
          <p class="description-text">
            {{ pictureData.year }}, {{ pictureData.technique }},
            {{ pictureData.height }} x {{ pictureData.width }} cm
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ImageView",
  data: function () {
    const pictureData = this.rawPictureData;
    pictureData.imgFull = require(`@/assets/img/${pictureData.imgFull}`);
    return {
      containerHeight: window.innerHeight * 0.8,
      pictureData: pictureData,
    };
  },
  props: {
    rawPictureData: Object,
  },
};
</script>

<style scoped>
.title-text {
  font-weight: bold !important;
}

.description-text {
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: 0.32px;
  line-height: 24px;
  color: #00000080;
}

/*section {*/
/*  scroll-snap-align: center;*/
/*}*/
</style>
