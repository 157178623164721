<template>
  <div class="mx-auto w-64 mt-12">
    <p class="grey-text main-text">
      {{ text }}
    </p>
  </div>
</template>

<script>
export default {
  name: "MainText.vue",
  props: {
    text: String,
  },
};
</script>

<style scoped>
.main-text {
  font-size: 24px;
  letter-spacing: 0.48px;
  line-height: 32px;
  text-align: center;
}
</style>
