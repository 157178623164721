<template>
  <div class="mt-20">
    <ImageGrid
      :gridCols="seriesData.gridCols"
      :pictureData="seriesData.pictures"
      :imgViewBaseId="imgViewIdBase"
      :imgAspectRatio="seriesData.gridAspectRatio"
    ></ImageGrid>
    <MainText v-bind:text="seriesData.text"></MainText>
    <div id="image-views-container">
      <ImageView
        v-for="picture in seriesData.pictures"
        :key="picture.title"
        v-bind:rawPictureData="picture"
        v-bind:id="imgViewIdBase + picture.id"
      >
      </ImageView>
    </div>
  </div>
  <div
    @click="scrollToTop"
    id="scrollToTopBtn"
    class="fixed bottom-6 right-6 h-14 w-14 rounded-full"
  >
    <fa-angle-up icon="angle-up" id="to-top-icon" />
  </div>
</template>

<script>
import ImageGrid from "@/components/Series/ImageGrid";
import MainText from "@/components/Series/MainText";
import ImageView from "@/components/Series/ImageView";

export default {
  name: "Series.vue",
  components: {
    ImageGrid,
    MainText,
    ImageView,
  },
  data: function () {
    return {
      seriesData: this.$store.getters.getSeriesByName(this.$route.params.name),
      imgViewIdBase: "img-view-",
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    toggleTopScrollButton() {
      if (
        document.body.scrollTop > 400 ||
        document.documentElement.scrollTop > 400
      ) {
        this.backToTopButton.style.display = "block";
      } else {
        this.backToTopButton.style.display = "none";
      }
    },
  },
  mounted() {
    // Get back to top button once in order to not query for it every time on scroll
    this.backToTopButton = document.getElementById("scrollToTopBtn");
    window.addEventListener("scroll", this.toggleTopScrollButton);
  },
};
</script>

<style scoped>
#scrollToTopBtn {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  border: none;
  outline: none;
  background-color: white;
  color: black;
  cursor: pointer;
  font-size: 18px;
  padding: 10px;
  box-shadow: 3px 3px 5px #888888;
}

#to-top-icon {
  width: 100%;
  height: 100%;
}
</style>
